/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
import fileDownload from "js-file-download";
import { Fab, Grid } from "@material-ui/core";
import { Skeleton, Alert, AlertTitle } from "@material-ui/lab";
import { RiRefund2Line, RiFileTransferFill } from "react-icons/ri";
import { BiReceipt } from "react-icons/bi";
import { AiOutlineWarning } from "react-icons/ai";
import Money from "@atoms/Money/Money";
import { getPaymentTypeTranslation } from "@molecules/Snippets/PaymentMethod";
import FenixTable from "@molecules/Tables/FenixTable/FenixTable";
import AccordionItem from "@molecules/Accordions/AccordionItem";
import { userAuthorized } from "@src/entries/fenix/auth/FenixAuthContext";
import PaymentsModalAdd from "./PaymentsModalAdd";
import PaymentsModalChangeBook from "./PaymentsModalChangeBook";
import {
  fetchPaymentsAndReceipts,
  executeConfirmRefund,
  executeDownloadReceipt,
  executeRestOfRefunds,
  executeActivatePayment,
  executeChangeRefundToTransfer,
} from "./usePayments";
// import demoData from "./__test__/PaymentList.json";

const Payments = ({ bookref, email, pvp }) => {
  // console.log(React);
  const { profile } = userAuthorized();
  const userValidator = profile.email;

  const [totalPaid, setTotalPaid] = useState(0);
  const [paymentsConfirmed, setPaymentsConfirmed] = useState([]);
  const [paymentsPending, setPaymentsPending] = useState([]);
  const [refundsConfirmed, setRefundsConfirmed] = useState([]);
  const [refundsPending, setRefundsPending] = useState([]);
  const [paymentsCancelled, setPaymentsCancelled] = useState([]);
  const [paymentsInactive, setPaymentsInactive] = useState([]);
  const [receipts, setReceipts] = useState([]);

  // Show states
  const [loading, setLoading] = useState(true);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalChange, setOpenModalChange] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);

  const [currentRowPaymentId, setCurrentRowPaymentId] = useState(null);

  const movePaymentToAnotherBook = (row) => {
    setCurrentRowPaymentId(row.paymentId);
    setOpenModalChange(true);
  };

  const actionsConfirmed = [
    { name: "Mover a otra reserva", icon: <RiFileTransferFill />, func: movePaymentToAnotherBook },
  ];
  const actionsRefund = [
    // { name: "Confirmar devolución", icon: <RiRefund2Line />, func: confirmRefund }
    { name: "Cambiar a devolución por transferencia", icon: <RiRefund2Line />, func: changeRefundTransfer },
  ];
  const actionsReceipt = [{ name: "Ver justificante", icon: <BiReceipt />, func: downloadReceipt }];

  const getPayments = useCallback(async () => {
    setLoading(true);

    const response = await fetchPaymentsAndReceipts(bookref);

    const newList = response.payments.reduce(
      (accumulative, payment) => {
        const accu = { ...accumulative };

        if (payment.status === 1) {
          if (payment.method === 999 && payment.refundedFromPaymentId !== undefined) {
            if (accu.refundsConfirmed[payment.refundedFromPaymentId] === undefined) {
              accu.refundsConfirmed[payment.refundedFromPaymentId] = [];
            }
            accu.refundsConfirmed[payment.refundedFromPaymentId].push(payment);
          } else {
            accu.paymentsConfirmed.push(payment);
          }
          accu.totalPaid += payment.amount.amount;
        }
        if (payment.status === 2) {
          accu.paymentsCancelled.push(payment);
        } else if (payment.status === 0) {
          accu.paymentsInactive.push(payment);
        } else if (payment.status === 3 || payment.status === 4) {
          if (payment.method === 999) {
            accu.refundsPending.push(payment);
          } else {
            accu.paymentsPending.push(payment);
          }
        }

        return accu;
      },
      {
        totalPaid: 0,
        paymentsConfirmed: [],
        paymentsPending: [],
        refundsConfirmed: {},
        refundsPending: [],
        paymentsCancelled: [],
        paymentsInactive: [],
      }
    );

    setTotalPaid(newList.totalPaid);
    setPaymentsConfirmed(newList.paymentsConfirmed);
    setPaymentsPending(newList.paymentsPending);
    setRefundsConfirmed(newList.refundsConfirmed);
    setRefundsPending(newList.refundsPending);
    setPaymentsCancelled(newList.paymentsCancelled);
    setPaymentsInactive(newList.paymentsInactive);

    setLoading(response.loading);
    setReceipts(response.receipts);

    // Send to old Fenix
    window.parent.postMessage(
      response.payments.map((payment) => {
        const dPayment = { ...payment };
        dPayment.amount = payment.amount.amount;
        return dPayment;
      }),
      "*"
    );
  }, [bookref]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  async function confirmRefund(row) {
    const r = confirm(
      `¿Seguro que quieres confirmar esta devolución de ${row.amount.amount / 100}${row.amount.currency}?
      Esta acción SOLO confirma en nuestro sistema que la devolución está hecha.
      SOLO debe hacerse cuando el pago esté devuelto en bancos.`
    );
    if (r) {
      await executeConfirmRefund(row.paymentId);
      getPayments();
    }
  }

  async function changeRefundTransfer(row) {
    const r = confirm(
      `¿Seguro que quieres cambiar este pago a devolución por transferencia? Esta acción cambiará la devolución a transferencia e informará al cliente para que introduzca su IBAN.`
    );
    if (r) {
      await executeChangeRefundToTransfer(row.paymentId);
      getPayments();
    }
  }

  async function downloadReceipt(row) {
    setLoadingReceipt(true);
    const response = await executeDownloadReceipt(row.bookref, row.id);
    fileDownload(Buffer.from(response.data.Body), row.location.path);
    setLoadingReceipt(false);
  }

  async function onExecuteRefunds() {
    setLoading(true);

    await executeRestOfRefunds(bookref);
    getPayments();
  }

  const clickOpenModalAdd = () => {
    setOpenModalAdd(!openModalAdd);
  };

  const closeModal = (reload) => {
    if (reload) {
      getPayments();
    }

    setOpenModalAdd(false);
  };

  const closeModalChange = (reload) => {
    if (reload) {
      getPayments();
    }

    setOpenModalChange(false);
  };

  async function activatePayment(row) {
    if (
      confirm(
        "¡Cuidado! Nuestro sistema NO HA PODIDO VERIFICAR que este pago este realmente activado. Por favor, activalos solo si estas seguro de que el pago esta confirmado en la plataforma del banco correspondiente."
      )
    ) {
      await executeActivatePayment(row.paymentId, userValidator);
      getPayments();
    }
  }

  return loading ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ maxHeight: "72px", padding: "12px" }}>
            <Skeleton variant="circle" width={130} height={50} animation="wave" />
          </div>
        </Grid>
      </Grid>
      <Skeleton variant="rect" height={300} animation="wave" />
    </>
  ) : (
    <>
      {/* Modales */}
      <PaymentsModalAdd bookData={{ bookref, email }} open={openModalAdd} onClose={closeModal} />
      <PaymentsModalChangeBook
        originalBookref={bookref}
        paymentId={currentRowPaymentId}
        open={openModalChange}
        onClose={closeModalChange}
      />

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <div style={{ maxHeight: "72px", padding: "12px" }}>
            <Fab variant="extended" color="secondary" onClick={clickOpenModalAdd}>
              {"Añadir pago"}
            </Fab>
          </div>
        </Grid>
        <Grid item xs={10}>
          <Alert severity="info">
            <AlertTitle>¡Atención! Esta sección funciona independiente al resto.</AlertTitle>
            Esto quiere decir que todo lo que añadas/modifiques{" "}
            <u>se ejecutará al momento SIN NECESIDAD DE GUARDAR</u>.
          </Alert>
        </Grid>
      </Grid>

      <div style={{ height: "20px" }}></div>

      {/* Tabla pagos y devoluciones confirmadas */}
      {paymentsConfirmed.length > 0 && (
        <FenixTable
          tableTitle={
            <TablePaymentsTitle
              pvp={pvp}
              paid={totalPaid}
              pendingRefundsAmount={refundsPending.reduce((total, refund) => total + refund.amount.amount, 0)}
              onExecuteRefunds={onExecuteRefunds}
            />
          }
          showInputFilter={false}
          tableHead={tableHeadPayments}
          tableContent={paymentsConfirmed.map((payment) => {
            return {
              ...payment,
              subrows:
                refundsConfirmed[payment.paymentId] !== undefined
                  ? refundsConfirmed[payment.paymentId].sort((a, b) => {
                      if (a.datecreation > b.datecreation) return 1;
                      if (a.datecreation < b.datecreation) return -1;
                      return 0;
                    })
                  : [],
            };
          })}
          sortDefault={{ direction: "asc", by: "datecreation" }}
          actions={actionsConfirmed}
        />
      )}
      <Grid container spacing={2}>
        {paymentsPending.length > 0 && (
          <Grid item xs={12} sm={12} lg={8}>
            {/* Tabla pagos Pendientes */}
            <FenixTable
              tableTitle={"Pagos Pendientes"}
              showInputFilter={false}
              tableHead={tableHeadPayments}
              tableContent={paymentsPending}
              sortDefault={{ direction: "asc", by: "datecreation" }}
            />
          </Grid>
        )}
        {receipts.length > 0 && (
          <Grid item xs={12} sm={12} lg={4}>
            <FenixTable
              loading={loadingReceipt}
              tableTitle={"Justificantes de pago"}
              showInputFilter={false}
              tableHead={tableHeadReceipt}
              tableContent={receipts}
              actions={actionsReceipt}
              sortDefault={{ direction: "asc", by: "creationstamp" }}
            />
          </Grid>
        )}
      </Grid>

      {/* Devoluciones pendientes */}
      {refundsPending.length > 0 && (
        <FenixTable
          tableTitle={"Devoluciones pendientes"}
          showInputFilter={false}
          tableHead={tableHeadPendingRefunds}
          tableContent={refundsPending}
          actions={actionsRefund}
          sortDefault={{ direction: "asc", by: "datecreation" }}
        />
      )}

      {/* Pagos anulados */}
      {paymentsCancelled.length > 0 && (
        <AccordionItem title={<div className="card-title h5">{"Ver pagos anulados"}</div>}>
          <FenixTable
            showInputFilter={false}
            tableHead={tableHeadPayments}
            tableContent={paymentsCancelled}
            sortDefault={{ direction: "asc", by: "datecreation" }}
          />
        </AccordionItem>
      )}

      {/* Pagos inactivos */}
      {paymentsInactive.length > 0 && (
        <AccordionItem title={<div className="card-title h5">{"Ver pagos inactivos"}</div>}>
          <Alert severity="error">
            <>
              ¡Atención! Los pagos inactivos son pagos que el sistema registra pero no estan confirmados por
              bancos. generalmente son pagos que el cliente accede a la pasarela de pago pero no continua en
              el proceso, por lo tanto el sistema no los considera como pagos confirmados. <br />
              <br />
              No obstante, en ocasiones algún pago inactivo puede ser un pago confirmado por fallos puntuales
              en comunicación entre el banco y nuestro sistema.
            </>
          </Alert>
          <FenixTable
            showInputFilter={false}
            tableHead={tableHeadPayments}
            tableContent={paymentsInactive}
            sortDefault={{ direction: "asc", by: "datecreation" }}
            actions={[
              {
                name: "Activar pago",
                icon: <RiFileTransferFill />,
                func: activatePayment,
              },
            ]}
          />
        </AccordionItem>
      )}
    </>
  );
};

const TablePaymentsTitle = ({ pvp, paid, pendingRefundsAmount, onExecuteRefunds }) => {
  // pendingRefundsAmount is a negative number
  const diffPrice = pvp - (paid + pendingRefundsAmount);

  const executeRefunds = () => {
    const r = confirm(
      `¡ATENCIÓN! Vas a ejecutar la devolucion de los ${(diffPrice * -1) / 100}€ que quedan por devolver.
      \nEsta ejecución calculará y generará las devoluciones pertinentes con las diferentes entidades bancarias DE FORMA AUTOMÁTICA. Es un proceso IRREVERSIBLE y tarda unos minutos en ejecutarse, por lo que es posible que debas volver a acceder a la reserva para ver los cambios.
      \n¿Quieres continuar?`
    );
    if (r) {
      onExecuteRefunds();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>{"Pagos y devoluciones"}</div>
      <div>
        {"Total pagado: "}
        <span style={{ fontWeight: "bold" }}>
          <Money amount={paid} />
        </span>
        {diffPrice < 0 && (
          <div style={{ display: "block", maxHeight: "72px", padding: "12px" }}>
            <Fab size={"small"} variant="extended" color="primary" onClick={executeRefunds}>
              <AiOutlineWarning style={{ marginRight: "5px" }} />
              <span style={{ marginRight: "5px" }}>{"Devolver"}</span>
              <span style={{ fontWeight: "bold" }}>
                <Money amount={diffPrice * -1} />
              </span>
            </Fab>
          </div>
        )}
      </div>
    </div>
  );
};

const tableHeadReceipt = [
  { code: "creationstamp", name: "Fecha subida", type: "date", format: "DD/MM/YYYY hh:mm:ss" },
  { code: "payerName", name: "Pagador", type: "string" },
];
const tableHeadCommon = [
  {
    code: "paycode",
    name: "Codigo de pago",
    type: "string",
  },
  {
    code: "datecreation",
    name: "Fecha de creación",
    type: "date",
    format: "DD/MM/YYYY hh:mm:ss",
  },
  {
    code: "datepayed",
    name: "Fecha de pago",
    type: "date",
    format: "DD/MM/YYYY hh:mm:ss",
  },
  {
    code: "amount.amount",
    name: "Cantidad",
    type: "money",
  },
  {
    code: "usercreation",
    name: "Creado por",
    type: "string",
  },
];

const tableHeadPayments = [
  {
    code: "method",
    name: "Metodo de pago",
    type: "label",
    translation: getPaymentTypeTranslation(),
  },
  ...tableHeadCommon,
  {
    code: "comment",
    name: "Comentario",
    type: "string",
  },
];

const tableHeadPendingRefunds = [
  {
    code: "refundedFromPaymentMethod",
    name: "Metodo de pago original",
    type: "label",
    translation: getPaymentTypeTranslation(),
  },
  {
    code: "refundedFromPaymentId",
    name: "Codigo de pago original",
    type: "string",
  },
  ...tableHeadCommon,
];

export default Payments;
