import axios from "axios";
import { v4 } from "uuid";

export const fetchPaymentsAndReceipts = async (bookref) => {
  try {
    const response = await axios.get(`/api/v1/booking-bc/payments`, { params: { bookref } });
    let payments = [];
    if (response.status === 200) {
      payments = response.data.data;
    }

    const responseR = await axios.get(`/api/v1/booking-bc/payments/transfer-receipt/${bookref}`);
    let receipts = [];
    if (responseR.status === 200) {
      receipts = responseR.data;
    }

    return { loading: false, payments, receipts };
  } catch (error) {
    console.error(error);
    return { loading: false, payments: [], receipts: [] };
  }
};

export const executeChangeRefundToTransfer = async (paymentId) => {
  try {
    const response = await axios.post(`/api/v1/booking-bc/payments-refund/to-transfer-refund/${paymentId}`);
    return response;
  } catch (error) {
    alert(`Ha ocurrido un error al cambiar el pago a transferencia. ${error}`);
  }
};

export const executeConfirmRefund = async (paymentId) => {
  try {
    const response = await axios.put(`/api/v1/booking-bc/payments-refund/${paymentId}`);
    if (response.status !== 202) {
      throw new Error(JSON.stringify(response));
    }
    return response;
  } catch (error) {
    alert(`Ha ocurrido un error al confirmar esta devolucion. ${error}`);
  }
};

export const executeDownloadReceipt = async (bookref, transferReceiptId) => {
  return await axios.get(
    `/api/v1/booking-bc/payments/transfer-receipt/${bookref}/download/${transferReceiptId}`
  );
};

export const postNewManualPayment = async (payment) => {
  const newPayment = {
    ...payment,
    paycode: "",
    status: 1,
  };
  const paymentId = v4();

  const response = await axios.post(`/api/v1/booking-bc/payments/${paymentId}`, newPayment);
  return response;
};

export const executeRestOfRefunds = async (bookref) => {
  try {
    const response = await axios.post("/api/v1/booking-bc/payments-refund/refund-a-book", { bookref });
    if (response.status !== 202) {
      throw new Error(JSON.stringify(response));
    }
    return response;
  } catch (error) {
    alert(`Ha ocurrido un error al generar devoluciones. ${error}`);
  }
};

export const executeChangeBookOfPayment = async (bookref, paymentId) => {
  try {
    const response = await axios.post(`/api/v1/booking-bc/payments/${paymentId}/move-to-book/${bookref}`, {});
    if (response.status !== 200) {
      throw new Error(JSON.stringify(response));
    }
    return response;
  } catch (error) {
    alert(`Ha ocurrido un error al mover el pago. ${error}`);
  }
};

export const executeActivatePayment = async (paymentId, userEmail) => {
  try {
    const headers = {
      "x-tr-trid-email": userEmail,
    };

    const response = await axios.put(
      `/api/v1/booking-bc/payments/manual-confirmation/${paymentId}`,
      {},
      { headers }
    );
    if (response.status !== 200) {
      throw new Error(JSON.stringify(response));
    }
    return response;
  } catch (error) {
    alert(`Ha ocurrido un error al activar el pago. ${error}`);
  }
};
